<template>
  <v-container fluid>
    <patient-filter
      :patients="patients"
      :panel="false"
      @filterChange="filterChange"
    ></patient-filter>

    <v-card class="py-1 mt-3">
      <v-tabs v-model="tab" class="ml-2" color="primary" icons-and-text>
        <v-tab v-for="s in status" :key="s.value">
          {{ s.text }}
          <v-icon>{{ s.icon }}</v-icon>
        </v-tab>
      </v-tabs>
    </v-card>
    <!-- <br />
    <v-divider /> -->

    <v-container fluid class="mt-4">
      <v-row v-if="appointments && appointments.length > 0" dense>
        <v-col
          v-for="item in appointments"
          :key="item.guid"
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="4"
        >
          <v-card class="v-card-profile">
            <v-card-text class="pb-0 pt-3">
              <v-row no-gutters>
                <v-col>
                  {{ item.appointmentDate | moment("YYYY/MM/DD hh:mm A") }}
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    class="mb-2"
                    small
                    @click="billDetail(item)"
                    text
                    color="primary"
                  >
                    <v-icon> mdi-receipt</v-icon>
                    {{ $t("openBill") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="12"
                  style="text-align: center"
                >
                  <v-avatar size="100">
                    <v-img
                      lazy-src="/assets/user-icon.svg"
                      aspect-ratio="1"
                      :src="
                        item.pictureUrl
                          ? item.pictureUrl
                          : require('@/assets/user-icon.svg')
                      "
                    >
                    </v-img>
                  </v-avatar>
                  <br />
                  <div class="mt-4">
                    {{ item.patientWork }}
                  </div>
                </v-col>
                <v-col cols="12" lg="9" md="9" sm="12">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-icon class="grey--text ma-1">
                        mdi-alert-circle-outline</v-icon
                      >
                      <v-btn
                        min-width="0"
                        :to="'/patientregistration/' + item.patientGuid"
                        target="_blank"
                        class="pa-0"
                        color="primary"
                        text
                        >{{ item.patientName }}</v-btn
                      >
                      <br />
                      <span>
                        <v-icon class="grey--text ma-1">
                          mdi-card-account-details-outline</v-icon
                        >
                        {{ item.patientCode }}
                      </span>
                      <br />
                      <v-icon class="grey--text ma-1">
                        mdi-gender-male-female</v-icon
                      >
                      {{ getGender(item.patientGender) }}
                      <br />
                      <span>
                        <v-icon class="grey--text ma-1">
                          mdi-map-marker-outline</v-icon
                        >
                        <span dir="auto"
                          >{{ item.countryName }},
                          {{ item.cityName }}
                          {{
                            item.patientAddress
                              ? ", " + item.patientAddress
                              : ""
                          }}
                        </span>
                      </span>
                      <br />
                      <span>
                        <v-icon class="grey--text ma-1">
                          mdi-cake-variant</v-icon
                        >
                        {{ (" " + item.patientDob) | moment("YYYY/MM/DD") }}
                        <br />
                        <v-icon class="grey--text ma-1">
                          mdi-phone-outline</v-icon
                        >
                        {{ item.patientMobile }}
                      </span>
                      <br />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <div v-if="item.appointmentStatus == 0">
                <v-btn
                  color="light-blue darken-1"
                  dark
                  class="mt-2"
                  @click="changeStatus(item, 1)"
                >
                  {{ $t("patients.treatment") }}
                </v-btn>
                <v-btn
                  text
                  :class="
                    ($vuetify.rtl ? 'float-left' : 'float-right') + ' mr-2 mt-2'
                  "
                  class="error"
                  @click="changeStatus(item, 2)"
                >
                  {{ $t("cancel") }}
                </v-btn>
              </div>

              <div v-if="item.appointmentStatus == 1">
                <v-btn
                  color="light-blue darken-1"
                  dark
                  class="mt-2 mx-1"
                  @click="changeStatus(item, 1)"
                >
                  {{ $t("patients.treatment") }}
                </v-btn>
                <v-btn
                  color="green"
                  dark
                  class="mt-2 mx-1"
                  @click="changeStatus(item, 3)"
                >
                  {{ $t("patients.done") }}
                </v-btn>
                <v-btn
                  :class="
                    ($vuetify.rtl ? 'float-left' : 'float-right') + ' mr-2 mt-2'
                  "
                  class="mt-2 mx-1"
                  @click="changeStatus(item, 0)"
                >
                  {{ $t("patients.wait") }}
                </v-btn>
              </div>

              <div v-if="item.appointmentStatus == 2">
                <v-btn
                  color="light-blue darken-1"
                  dark
                  class="mt-2"
                  @click="changeStatus(item, 1)"
                >
                  {{ $t("patients.treatment") }}
                </v-btn>

                <v-btn
                  :class="
                    ($vuetify.rtl ? 'float-left' : 'float-right') + ' mr-2 mt-2'
                  "
                  @click="changeStatus(item, 0)"
                >
                  {{ $t("patients.wait") }}
                </v-btn>
              </div>

              <div v-if="item.appointmentStatus == 3">
                <v-btn
                  color="light-blue darken-1"
                  dark
                  class="mt-2"
                  @click="changeStatus(item, 1)"
                >
                  {{ $t("patients.treatment") }}
                </v-btn>

                <v-btn
                  :class="
                    ($vuetify.rtl ? 'float-left' : 'float-right') + ' mr-2 mt-2'
                  "
                  @click="changeStatus(item, 0)"
                >
                  {{ $t("patients.wait") }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" style="text-align: center">
          {{ $t("noData") }}
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import PatientFilter from "../../components/PatientFilter.vue";
export default {
  components: { PatientFilter },
  data() {
    return {
      tab: 0,

      appointments: [],
      patients: [],
      panel: [],
      gender: [
        { value: 0, text: this.$t("patients.male") },
        { value: 1, text: this.$t("patients.female") },
      ],

      status: [
        { value: 0, text: this.$t("patients.waitTab"), icon: "mdi-clock" },
        { value: 1, text: this.$t("patients.treatmentTab"), icon: "mdi-heart" },
        {
          value: 2,
          text: this.$t("patients.cancelTab"),
          icon: "mdi-close-thick",
        },
        { value: 3, text: this.$t("patients.doneTab"), icon: "mdi-check-bold" },
      ],

      filterItem: {
        patientName: "",
        fromDate: this.dateNow,
        toDate: this.dateNow,
        status: null,
      },
    };
  },
  created() {
    this.refreshList();
    this.refreshPatients();
  },

  watch: {
    tab() {
      this.refreshList();
    },
  },
  methods: {
    getGender(i) {
      return this.gender.filter((o) => {
        return o.value == i;
      })[0].text;
    },

    billDetail(item) {
      axios.get("Bill/GetByAppointment?guid=" + item.guid).then((response) => {
        if (response.data.status == "Successful") {
          var guid = response.data.data.guid;
          //  this.$router.push({ path: "/billDetails/" + guid });
          //window.location.href = "/billDetails/" + guid;

          let route = this.$router.resolve({ path: "/billDetails/" + guid });
          window.open(route.href, "_blank");
        } else {
          this.$toast.error(this.$t("error." + response.data.message));
        }
      });
    },

    changeStatus(item, s) {
      axios
        .get("Appointment/ChangeStatus?guid=" + item.guid + "&status=" + s)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
          if (s == 1) {
            this.$router.push({ path: "/patienttreatment/" + item.guid });
          }
          // if (s == 3) {
          //   window.location.href = "/addPrescription/" + item.guid;
          // }

          this.refreshList(item.appointmentStatus);
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },

    filterChange(filterItem) {
      this.filterItem = filterItem;
      this.filterItem.status = this.filterStatus;
      this.refreshList();
    },
    refreshList() {
      axios
        .get(
          "Appointment/GetSchedule?status=" +
            this.tab +
            "&filter=" +
            JSON.stringify(this.filterItem)
        )
        .then((response) => {
          if (response.data.status == "Successful") {
            this.appointments = response.data.data;
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        });
    },
    async refreshPatients() {
      axios.get("Patient").then((response) => {
        this.patients = response.data.data;
      });
    },
  },
};
</script>

<style></style>
